<template>
  <div>
    <user-avatar :user="user" />
    <div
      v-if="can('manage-users') && showOptions"
      class="m-2"
    >
      <p v-if="status">
        {{ t('global.picture') }} {{ status.charAt(0).toUpperCase() + status.slice(1) }}
      </p>
      <!-- Show the buttons if -->
      <template v-if="status != 'required'">
        <v-btn
          v-if="status != 'approved'"
          class="mx-1"
          color="success"
          :text="t('actions.approve') "
          @click="approveImage(true)"
        />
        <v-btn
          v-if="status != 'rejected'"
          class="mx-1"
          color="error"
          :text="t('actions.reject') "
          @click="approveImage(false)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConfig, usePermissions } from '@/composables/index.ts';
import axios from 'axios';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import UserAvatar from '../images/UserAvatar.vue';

const props = defineProps<{
  user: App.Models.User;
}>();

const emit = defineEmits(['status-change', 'status-error']);

const { t } = useI18n();

const updateRoute = computed(() => `/api/users/${props.user.id}/profileimage`);

const { getConfig } = useConfig();
const { can } = usePermissions();

const showOptions = computed(() => {
  if (!getConfig('smpw.profile_image_required', false))
    return false;
  return !!props.user.profile_image;
});

const status = computed(() => props.user?.profile_image?.status);

async function approveImage(approved) {
  const verb = approved ? t('actions.approve') : t('actions.reject');
  const action = approved ? t('states.approved') : t('states.rejected');
  try {
    const result = await axios.post(
      updateRoute.value,
      {
        // eslint-disable-next-line object-shorthand
        approved: approved,
      },
    );
    if (result.data.result === 'success')
      emit('status-change', action);
  }
  catch (e) {
    emit('status-error', verb);
  }
}
</script>

<template>
  <div>
    <div class="d-flex justify-space-between">
      <v-breadcrumbs
        v-if="breadcrumbs"
        :items="breadcrumbs"
        color="primary-darken-1"
        active-color="black"
        density="compact"
        divider="›"
      />

      <div>
        <v-btn
          v-if="helpLink"
          v-tooltip="t('global.help')"
          :to="helpLink"
          color="primary"
          icon="fas fa-circle-question"
        />
        <v-btn
          v-if="adminHelpLink"
          v-tooltip="t('global.admin_help')"
          :to="adminHelpLink"
          color="primary"
          icon="fas fa-circle-question"
        />
        <v-btn
          v-if="pageLink"
          v-tooltip="t('global.go_to_page')"
          size="small"
          :to="pageLink"
          color="primary"
          icon="fas fa-magnifying-glass"
        />
      </div>
    </div>
    <v-divider
      class="my-2"
      thickness="2"
      opacity="100"
    />

    <global-header
      :title="title"
      :doc-header="!!docType"
    >
      <template #append>
        <slot name="append" />
      </template>
    </global-header>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import GlobalHeader from './GlobalHeader.vue';

const props = defineProps({
  items: {
    type: Array as PropType<BreadCrumb[]>,
    required: true,
  },
  admin: {
    type: Boolean,
    default: false,
  },
  docType: {
    type: String,
    required: false,
  },
  helpLink: {
    type: String,
    required: false,
  },
  adminHelpLink: {
    type: String,
    required: false,
  },
  pageLink: {
    type: String,
    required: false,
  },
});

const { t } = useI18n();

const homeCrumb: BreadCrumb = {
  title: t('global.home'),
  to: '/',
};
const adminCrumb: BreadCrumb = {
  title: t('global.admin'),
  to: '/admin',
};

const docCrumb = computed<BreadCrumb>(() => {
  if (props.docType) {
    return {
      title: `${props.docType.charAt(0).toUpperCase() + props.docType.slice(1)} ${t('global.documentation')}`,
      to: `/docs/${props.docType}`,
    };
  }
  return {
    title: t('global.missing'),
    to: '/docs/',
  };
});

const breadcrumbs = computed(() => {
  const crumbs = [
    homeCrumb,
    ...props.admin ? [adminCrumb] : [],
    ...props.docType ? [docCrumb.value] : [],
    ...props.items.map((c) => ({
      ...c,
      active: false,
    })),
  ];

  crumbs[crumbs.length - 1].active = true;

  return crumbs;
});

const title = computed(() => {
  const crumbs = breadcrumbs.value;
  return crumbs[crumbs.length - 1].title;
});
</script>

<template>
  <loading-spinner
    v-if="(loadingCount > 0) || !user"
  />
  <div v-else>
    <div
      v-if="((authUser.id === user.id) || can('manage-users')) && !readOnly"
      class="mb-2"
    >
      <v-btn
        color="primary"
        :text="t('user.edit_details')"
        size="default"
        class="me-2 mb-2"
        @click="editUser()"
      />
      <v-btn
        v-if="authUser.id === user.id"
        to="/changepassword"
        color="primary"
        size="default"
        class="me-2 mb-2"
        :text="t('password.change')"
      />
      <v-btn
        v-if="can('impersonate-users')"
        :href="`/impersonate/take/${user.id}`"
        color="primary"
        size="default"
        class="me-2 mb-2"
        :text="t('actions.impersonate')"
      />
      <v-btn
        color="error"
        size="default"
        class="me-2 mb-2"
        prepend-icon="fas fa-sign-out-alt"
        :text="t('login.log_out')"
        @click="logout()"
      />
    </div>
    <div
      v-else-if="(canAny(['manage-users', 'manage-circuit-users'])) && !readOnly"
    >
      <v-btn
        color="primary"
        :text="t('user.edit_details')"
        size="default"
        class="me-2 mt-2"
        @click="editUser()"
      />
    </div>

    <div
      v-if="!user.active"
    >
      <v-alert
        v-if="user.is_deactivated"
        class="my-2"
        type="error"
        :text="`${t('global.reason')}: ${user.deactivation_reason}`"
        :title="t('user.status.deactivated')"
      />
      <v-alert
        v-else
        class="my-2"
        type="error"
        :title="t('user.status.inactive')"
      />
    </div>

    <div
      v-if="showExtendedInfo"
    >
      <v-tabs
        v-model="tab"
      >
        <smpw-tab
          value="info"
          :active="tab === 'info'"
          :text="t('user.publisher_details')"
        />
        <smpw-tab
          v-if="canManageShifts"
          value="shifts"
          :active="tab === 'shifts'"
          :text="t('shift.title', 2)"
        />
        <smpw-tab
          v-if="canReadRatings"
          value="ratings"
          :active="tab === 'ratings'"
          :text="t('global.rating', 2)"
        />
        <smpw-tab
          value="notes"
          :active="tab === 'notes'"
          :text="t('global.notes')"
        />
        <smpw-tab
          value="log"
          :active="tab === 'log'"
          :text="t('global.log')"
        />
      </v-tabs>
      <v-window
        v-model="tab"
        :touch="false"
      >
        <v-window-item value="info">
          <user-personal-details
            :user="user"
            :show-extended-info="showExtendedInfo"
            @refresh="loadUserDetails"
          />
        </v-window-item>
        <v-window-item
          v-if="canManageShifts"
          value="shifts"
        >
          <v-alert
            v-if="!user.training_completed"
            class="mb-2"
            type="error"
            :title="t('user.status.training_incomplete', { name: user.full_name })"
          />

          <v-tabs
            v-model="shiftTab"
          >
            <smpw-tab
              value="current"
              :active="shiftTab === 'current'"
              :text="t('global.current')"
            />
            <smpw-tab
              value="calendar"
              :active="shiftTab === 'calendar'"
              :text="t('calendar.title')"
            />
            <smpw-tab
              value="list"
              :active="shiftTab === 'list'"
              :text="t('global.list')"
            />
          </v-tabs>
          <v-window
            v-model="shiftTab"
            :touch="false"
          >
            <v-window-item value="current">
              <shift-list :user="user" />
            </v-window-item>
            <v-window-item value="calendar">
              <shift-calendar
                :user="user"
                :dont-check-training-for-bookings="showExtendedInfo"
              />
            </v-window-item>
            <v-window-item value="list">
              <assigned-shift-list :user-id="userId" />
            </v-window-item>
          </v-window>
        </v-window-item>

        <v-window-item
          v-if="canReadRatings"
          value="ratings"
        >
          <v-table>
            <thead>
              <tr>
                <th>#</th>
                <th>{{ t('global.category') }}</th>
                <th>{{ t('global.rating') }}</th>
                <th>{{ t('task.comment') }}</th>
                <th>{{ t('date.last_update') }}</th>
                <th>{{ t('global.updated_by') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="ratingLabel in ratingLabels"
                :key="ratingLabel.code"
              >
                <td>{{ ratingLabel.id }}</td>
                <td>
                  <span class="font-weight-bold">{{ ratingLabel.label }}</span>
                  <ul
                    style="list-style: none"
                  >
                    <li
                      v-for="(point, index) in ratingLabel.subpoints"
                      :key="index"
                    >
                      <small class="ms-2">- {{ point }}</small>
                    </li>
                  </ul>
                </td>
                <td>{{ user[`rating_${ratingLabel.id}`] }}</td>
                <td>{{ user[`rating_${ratingLabel.id}_comment`] }}</td>
                <td>
                  {{ (user[`rating_${ratingLabel.id}_last_update`])
                    ? user[`rating_${ratingLabel.id}_last_update`] : 'N/A' }}
                </td>
                <td>
                  {{ (user[`rating_${ratingLabel.id}_edited_by_name`])
                    ? user[`rating_${ratingLabel.id}_edited_by_name`] : 'N/A' }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-window-item>

        <v-window-item
          v-if="user.notes !== undefined"
          value="notes"
        >
          <div
            v-if="user.notes"
            style="white-space: pre-wrap;"
          >
            {{ user.notes }}
          </div>
          <v-alert
            v-else
            type="info"
            :title="t('notes.none')"
          />
        </v-window-item>

        <v-window-item value="log">
          <activity-log
            :model-activity-log-route="`/api/users/${user.id}/activity`"
          />
        </v-window-item>
      </v-window>
    </div>
    <user-personal-details
      v-else
      :user="user"
      @refresh="loadUserDetails"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth, usePermissions } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import {
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ActivityLog from '../ActivityLog.vue';
import ShiftCalendar from '../ShiftCalendar.vue';
import ShiftList from '../ShiftList.vue';
import AssignedShiftList from './AssignedShiftList.vue';
import UserPersonalDetails from './UserPersonalDetails.vue';

const props = withDefaults(defineProps<{
  userId: number | string;
  showExtendedInfo?: boolean;
  readOnly?: boolean;
}>(), {
  showExtendedInfo: () => false,
  readOnly: () => false,
});

const { t } = useI18n();

const router = useRouter();

const { authUser, logout } = useAuth();
const { can, canAny } = usePermissions();

const tab = ref('info');
const shiftTab = ref('info');

interface RatingLabel {
  code: number;
  id: number;
  label: string;
  subpoints: string[];
}

const user = ref<App.Models.User>();
const loadingCount = ref(0);
const ratingLabels = ref<RatingLabel[]>([]);

const { showSnackMessage } = useErrorStore();

const canReadRatings = computed(() => can('manage-shifts') || can('manage-users'));
const canManageShifts = computed(() => can('manage-shifts') && can('manage-users'));

watch(() => props.userId, () => {
  loadUserDetails();
});
async function loadUserDetails() {
  loadingCount.value += 1;
  try {
    const response = await axios.get(`/api/users/${props.userId}`, {
      params: {
        inc: [
          'circuit',
          'congregation',
          'can_book_shifts',
          'courseSessions',
          'courseSessions.course',
          'languages',
          'languages_descr',
          'permissions_descr',
          'profile_image_status',
          'profile_image_url',
          'profileImage',
          'roles_descr',
          'teams_descr',
          'prefered_locations',
          'rating_1_edited_by_name',
          'rating_2_edited_by_name',
          'rating_3_edited_by_name',
          'rating_4_edited_by_name',
          'rating_5_edited_by_name',
          'spouse',
          'unwantedLocations',
        ],
      },
    });
    user.value = response.data.data;
  }
  catch (error) {
    console.error(error);
  }
  finally {
    loadingCount.value -= 1;
  }
}

function editUser() {
  router.push({ path: `/admin/users/${props.userId}/edit` });
}

async function loadUserRatingLabels() {
  loadingCount.value += 1;
  try {
    const response = await axios.get('/api/userratinglabels');
    ratingLabels.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('user_rating_label.cannot_load'));
    console.error(error);
  }
  finally {
    loadingCount.value -= 1;
  }
}

if (canReadRatings.value) {
  loadUserRatingLabels();
}

onMounted(() => {
  loadUserDetails();
});
</script>

<style scoped>
:deep(.dataTables_length) {
  padding-top: 3px;
}
</style>

<template>
  <div>
    <div v-if="authUser">
      <breadcrumb-header :items="breadcrumbs" />
    </div>
    <div v-else>
      <global-header :title="t('policies.cookie')" />
    </div>
    <v-container>
      <span
        class="tiptap text-font"
        v-html="getSetting('cookie_policy')"
      />
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useAuth, useSettings } from '@/composables';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { getSetting } = useSettings();
const { authUser } = useAuth();

const breadcrumbs: Array<BreadCrumb> = [
  {
    title: t('policies.cookie'),
  },
];
</script>

<template>
  <v-card
    density="comfortable"
    class="fill-height"
  >
    <v-card-title class="bg-primary">
      {{ t('dayoverseer.contact', { type_of_overseer: dayOverseer.order_name }) }}
    </v-card-title>
    <v-card-text class="mt-3">
      <user-avatar :user="dayOverseer.user" />
      <h5 class="text-h6 text-primary-darken-1">
        {{ dayOverseer.user.name }}
      </h5>
      <p
        v-if="dayOverseer.notes"
        class="text-subtitle-1 text-primary"
      >
        <small>
          {{ dayOverseer.notes }}
        </small>
      </p>
      <phone-number
        :number="dayOverseer.user.mobile_phone"
        class="mb-2"
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import UserAvatar from '../images/UserAvatar.vue';

defineProps<{
  dayOverseer: App.Models.DayOverseer;
}>();
const { t } = useI18n();
</script>

<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div>
    <div v-if="authUser">
      <breadcrumb-header :items="breadcrumbs" />
    </div>
    <div v-else>
      <global-header :title="t('policies.terms')" />
    </div>
    <v-container
      class="tiptap text-font"
      v-html="getSetting('terms_and_conditions')"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth, useSettings } from '@/composables';
import { useI18n } from 'vue-i18n';
import GlobalHeader from './GlobalHeader.vue';

const { t } = useI18n();

const { getSetting } = useSettings();
const { authUser } = useAuth();

const breadcrumbs: Array<BreadCrumb> = [
  {
    title: t('policies.terms'),
  },
];
</script>

<template>
  <div
    class="d-flex"
    :class="smAndDown ? 'flex-column' : 'flex-row'"
  >
    <div class="d-flex flex-column">
      <!-- Publishers Card -->
      <global-card
        :with-actions="false"
        :title="t('user.card')"
        class="text-center mb-3"
        :class="{ 'mr-3': smAndUp }"
      >
        <user-profile-image
          class="mt-2"
          :user="user"
          update-route="/api/users/{user.id}/profileimage"
          @status-change="onProfileImageStatusChange"
          @status-error="onProfileImageStatusError"
        />
        <v-card-text>
          <span class="text-h6 text-primary-darken-1">
            {{ user.full_name }}
          </span>
          <br>
          <span
            v-if="user.congregation"
            class="text-primary text-subtitle-1"
          >
            {{ user.congregation.name }}
          </span>
          <br>
          <span
            v-if="user.circuit"
            class="text-primary text-subtitle-1"
          >
            {{ user.circuit.name }}
          </span>
          <br>
          <phone-number
            v-if="user.mobile_phone"
            class="mb-2"
            :number="user.mobile_phone"
          />
          <phone-number
            v-else-if="user.landline_phone"
            :number="user.landline_phone"
            class="mb-2"
          />

          <span v-else>-</span>
          <br>
          <a
            variant="plain"
            class="text-primary-darken-1 text-lowercase text-decoration-none text-subtitle-1"
            :href="`mailto:${user.email}`"
          >
            <v-icon
              color="primary-darken-1"
              icon="fas fa-envelope"
              class="me-1"
            />
            {{ user.email }}
          </a>
        </v-card-text>
      </global-card>
      <!-- Personal Details -->
      <global-card
        :with-actions="false"
        :title="t('user.personal_information')"
        class="mb-3"
        :class="{ 'mr-3': smAndUp }"
      >
        <v-card-text>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('user.year_of_birth') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ user.year_of_birth ? user.year_of_birth : '-' }}
          </p>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('user.date_of_baptism') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{
              user.date_of_baptism
                ? toLongDate(user.date_of_baptism)
                : '-'
            }}
          </p>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('email.title') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ user.email }}
          </p>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('language.title', 2) }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ user.languages_descr ? user.languages_descr : '-' }}
          </p>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('language.preferred_language') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ user.preferred_language ?? '-' }}
          </p>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('global.spouse') }}
          </p>
          <p
            v-if="spouseDetailsUrl && user.spouse"
            class="mb-1 ms-3 text-subtitle-1 text-primary-darken-1"
          >
            <router-link :to="spouseDetailsUrl">
              <u>{{ user.spouse.full_name }}</u>
            </router-link>
          </p>
          <p
            v-else
            class="mb-1 ms-3 text-subtitle-1"
          >
            -
          </p>
        </v-card-text>
      </global-card>
      <!-- Emergency Contact Details -->
      <global-card
        :with-actions="false"
        :title="t('user.emergency_contact')"
        class="mb-3"
        :class="{ 'mr-3': smAndUp }"
      >
        <v-card-text>
          <p class="text-subtitle-1 text-primary-darken-1">
            {{ t('user.emergency_contact') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{
              user.emergency_contact_name ? user.emergency_contact_name : '-'
            }}
          </p>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('global.phone') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            <phone-number
              v-if="user.emergency_contact_phone"
              :number="user.emergency_contact_phone"
            />
            <span v-else>-</span>
          </p>
        </v-card-text>
      </global-card>
    </div>
    <div class="d-flex flex-column">
      <!-- Congregation -->
      <global-card
        :with-actions="false"
        :title="t('congregation.title')"
        class="mb-3"
        :class="{ 'mr-3': smAndUp }"
      >
        <v-card-text>
          <div v-if="user.congregation">
            <p class="text-subtitle-1 text-primary-darken-1">
              {{ t('congregation.name') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.congregation.name }}
            </p>
            <p class="text-subtitle-1 text-primary-darken-1">
              {{ t('congregation.within_city_limits') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.congregation.within_city_limits ? 'Yes' : 'No' }}
            </p>
          </div>
          <div v-if="user.circuit">
            <p class="text-subtitle-1 text-primary-darken-1">
              {{ t('circuit.name') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.circuit.name }}
            </p>
          </div>
          <p class="text-subtitle-1 text-primary-darken-1">
            {{ t('smpw.appointed') }}
          </p>
          <p
            v-if="user.appointed"
            class="mb-1 ms-3 text-subtitle-1"
          >
            {{ t(`appointment.${user.appointed}`) }}
          </p>
          <p
            v-else
            class="mb-1 ms-3 text-subtitle-1"
          >
            {{ t('global.not_appointed') }}
          </p>
          <p class="text-subtitle-1 text-primary-darken-1">
            {{ t('user.publisher_type') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ t(`user.types.${user.type}`) }}
          </p>
        </v-card-text>
      </global-card>
      <!-- Account -->
      <global-card
        :with-actions="false"
        :title="t('user.account')"
        class="mb-3"
        :class="{ 'mr-3': smAndUp }"
      >
        <v-card-text>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('global.username') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ user.username }}
          </p>

          <div v-if="showExtendedInfo">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('user.qualified_trainer') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.qualified_trainer ? 'Yes' : 'No' }}
            </p>
          </div>

          <div v-if="showExtendedInfo && user.can_be_captain">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('global.qualifies_as') }}
              {{ getSetting('captain_label') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.qualified_captain ? 'Yes' : 'No' }}
            </p>
          </div>

          <div v-if="showExtendedInfo">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('user.has_stand') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.has_stand ? 'Yes' : 'No' }}
            </p>
          </div>

          <div v-if="user.roles_descr">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('role.access') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.roles_descr }}
            </p>
          </div>

          <div v-if="user.teams_descr">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('team.title', 2) }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.teams_descr }}
            </p>
          </div>

          <div v-if="user.permissions_descr">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('permission.access') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{ user.permissions_descr }}
            </p>
          </div>

          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('shift.cancellation.rate') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ (parseFloat(user.calc_cancellation_rate) * 100).toFixed(2) }} %
          </p>

          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('shift.cancellation.last_minute_rate') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ (parseFloat(user.calc_last_minute_cancellation_rate) * 100).toFixed(2) }} %
          </p>

          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('login.last') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{
              user.last_login
                ? toLongDateWithTime(user.last_login)
                : t('date.never')
            }}
          </p>

          <div v-if="showExtendedInfo">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('date.last_accessed') }}
            </p>
            <p class="mb-1 ms-3 text-subtitle-1">
              {{
                user.last_access
                  ? toLongDateWithTime(user.last_access)
                  : t('date.never')
              }}
            </p>
          </div>
        </v-card-text>
      </global-card>
    </div>

    <div
      class="d-flex flex-column"
    >
      <!-- Shift Preferences -->
      <global-card
        :with-actions="false"
        :title="t('user.shift_preferences')"
        class="mb-3"
        :class="{ 'mr-3': smAndUp }"
      >
        <v-card-text>
          <p class="mb-1 text-subtitle-1 text-primary-darken-1">
            {{ t('shift.per_week') }}
          </p>
          <p class="mb-1 ms-3 text-subtitle-1">
            {{ user.shifts_per_week }}
          </p>
          <div v-if="getConfig('smpw.allow_availability_location_selection')">
            <p class="mb-1 text-subtitle-1 text-primary-darken-1">
              {{ t('location.preferred') }}
            </p>
            <div class="mb-1 ms-3 text-subtitle-1">
              <ul>
                <li
                  v-for="location in user.prefered_locations"
                  :key="location.id"
                >
                  {{ location.name }}
                </li>
              </ul>
            </div>
          </div>
        </v-card-text>
      </global-card>

      <!-- All Training Sessions -->
      <global-card
        :with-actions="false"
        :title="t('course.training_sessions')"
        class="mb-3"
        :class="{ 'mr-3': smAndUp }"
      >
        <v-card-text>
          <!-- Completed Trainings -->

          <v-alert
            v-if="completedTraining.length === 0"
            :title="t('states.completed')"
            :text="t('user.status.no_completed_training')"
            class="text-font"
          />

          <v-alert
            v-for="(courseSession, courseIndex) in completedTraining"
            :key="courseIndex"
            type="success"
            icon="fa-solid fa-square-check"
            class="my-1"
            :title="courseSession.course?.name"
          >
            {{ t('date.completed_on', { date: toLongDate(courseSession.date) }) }}
            <v-btn
              v-if="showExtendedInfo && can('manage-course-sessions')"
              v-tooltip="t('course.delete_training')"
              color="error"
              class="ma-2"
              @click="showUninviteModal(courseSession)"
            >
              <v-icon icon="fas fa-trash" />
            </v-btn>
          </v-alert>

          <!-- Pending Trainings -->

          <v-alert
            v-if="invitedTraining.length === 0"
            :text="t('course.no_invites')"
            :title="t('course.invitations')"
            class="my-1 text-font"
          />

          <v-alert
            v-for="(courseSession, courseIndex) in invitedTraining"
            :key="courseIndex"
            type="warning"
            class="my-1"
            :title="courseSession.course?.name"
            icon="fas fa-clock"
          >
            {{ t('date.invited_for', { date: toLongDate(courseSession.date) }) }}
            <v-btn
              v-if="can('manage-course-sessions')"
              v-tooltip="t('course.uninvite')"
              color="error"
              class="ma-2"
              @click="showUninviteModal(courseSession)"
            >
              <v-icon icon="fas fa-trash" />
            </v-btn>
          </v-alert>

          <!-- Declined Trainings -->

          <v-alert
            v-if="declinedTraining.length === 0"
            :title="t('course.declined')"
            :text="t('course.no_declined_training')"
            class="my-1 text-font"
          />

          <v-alert
            v-for="(courseSession, courseIndex) in declinedTraining"
            :key="courseIndex"
            type="error"
            class="my-1"
            icon="fa-solid fa-square-xmark"
            :title="courseSession.course?.name"
          >
            {{ t('course.declined') }}:
            {{ toLongDate(courseSession.date) }}
            <v-btn
              v-if="can('manage-course-sessions')"
              v-tooltip="t('course.remove')"
              color="error"
              class="ma-2"
              @click="showUninviteModal(courseSession)"
            >
              <v-icon icon="fas fa-trash" />
            </v-btn>
          </v-alert>
        </v-card-text>
      </global-card>
      <!-- Approvals -->
      <global-card
        v-if="shouldDisplayQualifications"
        :with-actions="false"
        class="mb-3"
        :class="{ 'mr-3': smAndUp }"
        :title="t('global.approvals')"
      >
        <v-card-text>
          <div
            v-if="user.qualified_captain"
            class="mb-1"
          >
            <v-btn
              color="primary"
              class="me-3 hover_off w-25"
              size="default"
              variant="flat"
              :ripple="false"
            >
              <v-icon
                size="large"
                :icon="user.gender === 'b' ? 'fas fa-user-tie' : 'fas-fa-user'"
              />
            </v-btn>
            <span class="text-font">
              {{ t('global.approved_as', { title: getSetting('captain_label') }) }}
            </span>
          </div>
          <div
            v-if="user.qualified_trainer"
            class="mb-1"
          >
            <v-btn
              color="success"
              size="default"
              variant="flat"
              :ripple="false"
              class="me-3 hover_off w-25"
            >
              <v-icon
                icon="fas fa-chalkboard-teacher"
                size="large"
              />
            </v-btn>
            {{ t('course.approved_trainer') }}
          </div>
        </v-card-text>
      </global-card>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <global-card
        :with-actions="true"
        :title="t('course.uninvite')"
        closable
        @close="dialog = false"
      >
        {{ t('course.uninvite_confirmation_long', { name: user.full_name, course: selectedCourseSession?.course?.name }) }}
        <template #actions>
          <v-btn
            color="dark-grey"
            :text="t('global.no')"
            @click="dialog = false"
          />
          <v-btn
            color="primary"
            :text="t('global.yes')"
            @click="uninviteFromCourseSession"
          />
        </template>
      </global-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import {
  useAuth,
  useConfig,
  useDate,
  usePermissions,
  useSettings,
} from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import UserProfileImage from './UserProfileImage.vue';

const props = withDefaults(defineProps<{
  user: App.Models.User;
  showExtendedInfo?: boolean;
}>(), {
  showExtendedInfo: () => false,
});

const emit = defineEmits(['refresh']);

const { t } = useI18n();
const { smAndUp } = useDisplay();
const { getConfig } = useConfig();
const { authUser } = useAuth();
const { getSetting } = useSettings();
const { can } = usePermissions();
const { toLongDate, toLongDateWithTime } = useDate();
const { showSnackMessage } = useErrorStore();

const { smAndDown } = useDisplay();

const dialog = ref(false);
const selectedCourseSession = ref<App.Models.CourseSession>();
const shouldDisplayQualifications = computed(() => props.user.qualified_captain || props.user.qualified_trainer);

const spouseDetailsUrl = computed(() => {
  if (!props.user.spouse) {
    return undefined;
  }
  if (props.showExtendedInfo) {
    return `/admin/users/${props.user.spouse.id}`;
  }
  if (props.user.id === authUser.id) {
    return '/myspousedetails';
  }
  if (props.user.id === authUser.spouse_id) {
    return '/mydetails';
  }
  return undefined;
});

const completedTraining = computed(() => {
  if (props.user.course_sessions) {
    return props.user.course_sessions.filter((s) => (s.pivot.status === 'attended'));
  }
  return [];
});

const invitedTraining = computed(() => {
  if (props.user.course_sessions) {
    return props.user.course_sessions.filter((s) => (s.pivot.status === 'pending') || (s.pivot.status === 'accepted'));
  }
  return [];
});

const declinedTraining = computed(() => {
  if (props.user.course_sessions) {
    return props.user.course_sessions.filter((s) => (s.pivot.status === 'declined'));
  }
  return [];
});

async function onProfileImageStatusChange(newStatus) {
  showSnackMessage(t('images.profile_status', { status: newStatus }), 'success');
  emit('refresh');
}

async function onProfileImageStatusError(verb) {
  showSnackMessage(t('images.cannot_update', { verb }));
}

function showUninviteModal(courseSession) {
  selectedCourseSession.value = courseSession;
  dialog.value = true;
}

async function uninviteFromCourseSession() {
  if (!selectedCourseSession.value?.id) {
    return;
  }
  try {
    await axios.delete(`/api/coursesessions/${selectedCourseSession.value.id}/uninvite`, { params: { user_id: props.user.id } });
    emit('refresh');
    dialog.value = false;
    selectedCourseSession.value = undefined;
  }
  catch (error) {
    showSnackMessage(t('course.cannot_uninvite', { name: props.user.full_name }));
  }
}
</script>

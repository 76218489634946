<template>
  <global-card
    :min-width="computedMinWidth"
    :max-width="maxWidth"
    :title="`${saveButtonWording} ${modelName}`"
    :heading-color="headingColor"
    :show-heading="withTitle"
    :closable="canClose"
    :without-text="!withText"
    with-actions
    @close="close"
  >
    <v-form>
      <slot />
    </v-form>
    <template #actions>
      <v-spacer />

      <v-btn
        v-if="canClose"
        color="dark-grey"
        :text="t('actions.close')"
        @click="close"
      />
      <slot name="actions" />

      <v-btn
        v-if="mode !== 'show'"
        color="primary"
        :text="saveButtonWording"
        @click="save"
      />
    </template>
  </global-card>
</template>

<script setup lang="ts">
import type { AxiosError } from 'axios';
import { useDataTableUtilities } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify/lib/framework.mjs';

const props = withDefaults(defineProps<{
  baseUrl: string;
  modelName: string;
  modelId?: number | string;
  mode: 'edit' | 'create' | 'show' | 'clone';
  modelUpdateUrl?: string;
  modelShowUrl?: string;
  modelCreationUrl?: string;
  includes?: Array<string>;
  fields?: Array<string>;
  minWidth?: string;
  maxWidth?: string;
  withTitle?: boolean;
  canClose?: boolean;
  saveWording?: string;
  headingColor?: string;
  withText?: boolean;
  loadWritable?: boolean;
  successMessage?: string;
}>(), {
  includes: () => [],
  fields: () => [],
  withTitle: () => true,
  canClose: () => true,
  headingColor: () => 'primary',
  withText: () => true,
  loadWritable: () => true,
  maxWidth: () => '1000px',
});

const emit = defineEmits(['model:updated', 'model:created', 'model:loaded', 'error:handled', 'close']);

const { width, lgAndUp } = useDisplay();

const computedMinWidth = computed(() => {
  if (props.minWidth) {
    return props.minWidth;
  }

  if (lgAndUp.value) {
    return width.value * 0.5;
  }
  return width.value * 0.75;
});

const { t } = useI18n();
const { showSnackMessage } = useErrorStore();
const { appendFields, appendIncludes } = useDataTableUtilities();
const item = defineModel<any>();
const errorStore = useErrorStore();
const { validationErrors } = storeToRefs(errorStore);

const updateUrl = computed(() => {
  if (props.modelUpdateUrl) {
    return props.modelUpdateUrl;
  }
  return `${props.baseUrl}/${props.modelId}`;
});

const showUrl = computed<string>(() => {
  let url = `${props.baseUrl}/${props.modelId}`;
  if (props.modelShowUrl) {
    url = props.modelShowUrl;
  }
  if (props.includes.length > 0) {
    url = appendIncludes(url, props.includes);
  }

  if (props.fields.length > 0) {
    url = appendFields(url, props.fields);
  }

  return url;
});

const saveUrl = computed(() => {
  if (props.modelCreationUrl) {
    return props.modelCreationUrl;
  }
  return `${props.baseUrl}`;
});

const saveButtonWording = computed(() => {
  if (props.saveWording) {
    return props.saveWording;
  }
  return props.mode === 'edit' ? t('global.update') : t('actions.create');
});

async function loadModel() {
  if (props.mode === 'create') {
    return;
  }
  try {
    const response = await axios.get(showUrl.value);
    item.value = response.data.data;
    emit('model:loaded');
  }
  catch (error) {
    const errors = error as Error | AxiosError;
    if (axios.isAxiosError(errors)) {
      showSnackMessage(errors?.response?.data?.message);
    }
    else {
      showSnackMessage(t('errors.cannot_load'));
      console.error(error);
    }
  }
}

function close() {
  emit('close');
}

async function save() {
  try {
    if (props.modelId && props.mode !== 'clone') {
      await axios.put(updateUrl.value, item.value);
    }
    else {
      await axios.post(saveUrl.value, item.value);
    }
    validationErrors.value = {};
    if (props.mode === 'create' || props.mode === 'clone') {
      emit('model:created');
      showSnackMessage(props.successMessage ?? `${props.modelName} ${t('states.created')}`, 'success');
      close();
    }

    if (props.mode === 'edit') {
      emit('model:updated');
      showSnackMessage(props.successMessage ?? `${props.modelName} ${t('states.updated')}`, 'success');
      close();
    }
  }
  catch (error) {
    const errors = error as Error | AxiosError;
    const action = t(`actions.${props.mode}`);
    let errorMessage = `${t('global.could_not')} ${action} ${props.modelName}`;
    if (axios.isAxiosError(errors)) {
      if (errors.response?.status === 422) {
        validationErrors.value = errors.response.data.errors;
        errorMessage = errors?.response?.data?.message;
      }
      else {
        errorMessage = errors?.response?.data?.message;
      }
    }
    showSnackMessage(errorMessage);
  }
}

const validate = debounce(async (field: string = '') => {
  const headers = { Precognition: true };
  if (field) {
    headers['Precogntion-Validate-Only'] = field;
  }
  try {
    if (props.modelId && props.mode !== 'clone') {
      await axios.put(updateUrl.value, item.value, { headers });
    }
    else {
      await axios.post(saveUrl.value, item.value, { headers });
    }
    validationErrors.value = {};
  }
  catch (error) {
    const errors = error as Error | AxiosError;
    if (axios.isAxiosError(errors)) {
      if (errors.response?.status === 422) {
        validationErrors.value = errors.response.data.errors;
      }
    }
  }
}, 1000);

loadModel();
defineExpose({ save, validate });
</script>

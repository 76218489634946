<template>
  <div>
    <breadcrumb-header
      v-if="withBreadcrumbs"
      :items="breadcrumbs"
    />
    <v-tabs
      v-if="store?.images?.length"
      v-model="tab"
    >
      <smpw-tab
        value="info"
        :active="tab === 'store_info'"
        :text="t('global.info')"
      />
      <smpw-tab
        v-if="store?.images?.length"
        value="images"
        :active="tab === 'images'"
        :text="t('images.title', 2)"
      />
    </v-tabs>
    <v-window
      v-if="store"
      v-model="tab"
    >
      <v-window-item value="info">
        <div
          v-if="store.details"
          class="tiptap text-font"
          v-html="store.details"
        />
        <store-marker
          v-model="store"
          read-only
        />

        <v-card>
          <v-toolbar
            color="primary"
          >
            <v-icon
              icon="fas fa-warehouse"
              class="mx-5"
            />
            <span>{{ t('store.title') }}</span>
          </v-toolbar>
          <v-row class="my-2">
            <v-col cols="1">
              <v-img
                src="/images/mapmarkers/pin_store.png"
                max-height="30px"
              />
            </v-col>
            <v-col>
              <strong class="me-2 text-subtitle-1">{{ store.name }}</strong>
              <v-btn
                :href="`https://www.google.com/maps/dir/?api=1&destination=${store.lat},${store.lng}`"
                target="_blank"
                prepend-icon="fas fa-compass"
                :text="t('global.directions')"
                color="primary"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
      <v-window-item value="images">
        <image-gallery
          v-if="store.images?.length"
          v-model="store.images"
          enable-slide-show
          read-only
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ImageGallery from '../images/ImageGallery.vue';
import StoreMarker from './StoreMarker.vue';

const props = withDefaults(defineProps<{
  id: string | number;
  withBreadcrumbs?: boolean;
}>(), {
  withBreadcrumbs: () => true,
});
const { t } = useI18n();
const { showSnackMessage } = useErrorStore();
const store = ref<App.Models.Store | null>();
const tab = ref('info');
const breadcrumbs = computed(() => [
  {
    title: t('store.and_locations'),
    to: '/locations',
  },
  {
    title: store.value?.name ?? t('states.loading'),
  },
]);

async function loadStore() {
  try {
    const response = await axios.get(`/api/stores/${props.id}?inc[]=images`);
    store.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('store.cannot_load'));
  }
}

loadStore();
</script>

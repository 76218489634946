<template>
  <v-card class="mb-2">
    <v-card-title class="bg-primary-lighten-1 d-flex flex-row justify-space-between">
      <div>
        <v-icon
          :icon="`fa-fw fa fa-${(task.category?.icon) ? task.category?.icon : 'clipboard-check'}`"
          color="dark-grey"
          class="me-2"
        />
        <span class="text-body-1 text-primary font-weight-bold text-wrap">{{ task.title }}</span>
      </div>
      <div>
        <v-btn
          v-if="mode == 'index'"
          color="primary"
          class="me-1"
          :to="`/tasks/${task.id}`"
        >
          <v-icon icon="fas fa-eye" />
        </v-btn>
        <task-edition-dialog
          :model-id="task.id"
          mode="edit"
          size="small"
          @reload="emit('reload')"
        />
        <deletion-dialog
          model-name="Task"
          :delete-url="`/api/tasks/${task.id}`"
          size="small"
          @model:deleted="onDeleted"
        />
      </div>
    </v-card-title>

    <v-card-title class="bg-light-grey d-flex flex-row justify-space-between">
      <v-row
        justify="start"
        class="pa-1"
      >
        <v-col>
          <v-chip
            :color="getStatusBadgeClass(task.status)"
            size="small"
            class="me-2"
          >
            {{ t(`states.${task.status}`) }}
            {{ (task.status === 'completed') ? `: ${toLongDateWithDay(task.complete_date)}` : '' }}
          </v-chip>
          <v-chip
            color="secondary"
            class="me-2"
            size="small"
            :text="task.category?.name"
          />
          <v-chip
            v-if="task.due_date && (task.status != 'completed')"
            size="small"
            class="me-2"
            :color="`${isBeforeToday(task.due_date) ? 'warning' : 'error'}`"
            :text="`${t('task.due')}: ${toLongDateWithDay(task.due_date)}`"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="mt-2">
      <v-row>
        <v-col
          class="d-flex flex-row align-end"
        >
          <v-icon
            icon="fas fa-warehouse"
            class="me-3"
            color="dark-grey"
          />
          <span class="text-body-2">{{ task.store?.name }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="mt-n2 d-flex flex-row"
        >
          <user-avatar
            v-if="task.assignee"
            :user="task.assignee"
            size="35"
            class="me-4 ms-n1"
          />

          <div class="d-flex flex-column align-stretch">
            <div>
              <span
                v-if="task.assignee"
                class="text-body-2 mb-auto mt-auto"
              >
                {{ task.assignee?.full_name }}
              </span>
              <phone-number
                v-if="task.assignee"
                size="x-small"
                class="mb-1"
                class-name="text-subtitle-2"
                :number="task.assignee.mobile_phone"
              />
            </div>
            <small class="text-subtitle-2 mb-auto mt-2">
              <span>#{{ task.id }} - {{ t('task.opened_by') }} </span>

              <a
                v-if="task.reporter"
                class="mx-1"
                :href="`tel:${task.reporter.mobile_phone}`"
              >{{ task.reporter?.name }}</a>
              <span v-else> Unknown </span>
              <span v-if="isInFutureByAtLeast(task.created_at)">on {{ toLongDateWithDayAndTime(task.created_at) }}</span>
              <span v-else>{{ fromNow(task.created_at) }}</span>
              <span class="mx-2">-</span>
              <v-icon
                icon="far fa-comments"
                size="small"
                class="me-2"
              />
              <small>
                {{ t('task.comment', { count: task.comments?.length ?? 0 }, task.comments?.length ?? 0) }}
              </small>
            </small>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import UserAvatar from '@/components/images/UserAvatar.vue';
import { useDate } from '@/composables';
import { useI18n } from 'vue-i18n';
import DeletionDialog from '../crud/DeletionDialog.vue';
import TaskEditionDialog from './TaskEditionDialog.vue';
import { useTaskUtilities } from './useTaskUtilities';

defineProps<{
  task: App.Models.Task;
  mode: 'show' | 'index';
}>();

const emit = defineEmits(['reload', 'model:deleted']);

const { t } = useI18n();

const { getStatusBadgeClass } = useTaskUtilities();
const {
  isInFutureByAtLeast,
  toLongDateWithDayAndTime,
  fromNow,
  isBeforeToday,
  toLongDateWithDay,
} = useDate();

function onDeleted() {
  emit('model:deleted');
  emit('reload');
}
</script>

<template>
  <model-edition-form
    ref="form"
    v-model="experience"
    mode="create"
    :model-name="t('experience.title')"
    success-message="Experience submitted successfully. It will be reviewed by our team."
    base-url="/api/experiences/submit"
    @close="emit('close')"
  >
    <date-picker
      v-model="experience.date"
      :error-messages="validationErrors.date ?? []"
      :error="!!validationErrors.date"
      :max="toUniversalDate(undefined)"
      :label="t('date.title')"
      :clearable="false"
      :hint="t('experience.date_hint')"
    />
    <model-select-dropdown
      v-model="experience.location_id"
      index-route="/api/locations"
      :index-route-parameters="{ active: true }"
      label-field-name="name"
      sort-field-name="name"
      load-all
      :error-messages="validationErrors.location_id ?? []"
      :error="!!validationErrors.location_id"
      :persistent-hint="false"
      :label="t('location.title')"
      :hint="t('experience.location_hint')"
    />
    <v-text-field
      v-model="experience.title"
      name="title"
      :error-messages="validationErrors.title ?? []"
      :label="t('global.title')"
      :hint="t('experience.title_hint')"
    />
    <v-textarea
      v-model="experience.experience"
      name="experience"
      :error-messages="validationErrors.experience ?? []"
      :error="!!validationErrors.experience"
      :label="t('experience.title')"
      :hint="t('experience.experience_hint')"
    />
  </model-edition-form>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ModelSelectDropdown from '../crud/ModelSelectDropdown.vue';
import ModelEditionForm from '../ModelEditionForm.vue';

const emit = defineEmits(['close']);
const { t } = useI18n();
const { toUniversalDate } = useDate();
const defaultItem = {
  date: toUniversalDate(undefined),
  location_id: undefined,
  title: '',
  experience: '',
};

const experience = ref<Partial<App.Models.Experience>>(defaultItem);
if (!experience.value) {
  experience.value = defaultItem;
}

const form = ref();
const errorStore = useErrorStore();
const { validationErrors } = storeToRefs(errorStore);
watch(experience, () => {
  form.value.validate();
}, { deep: true });
</script>

<template>
  <v-btn
    prepend-icon="fas fa-times"
    color="error"
    block
    size="default"
    class="mb-1"
    @click="show"
  >
    {{ t('shift.cancellation.title') }}
    <v-dialog
      v-model="cancellationDialog"
      max-width="500px"
    >
      <global-card
        heading-color="error"
        :title="t('shift.cancellation.title')"
      >
        <v-select
          v-model="selectedReasonId"
          :label="t('cancellation_reason.title')"
          item-value="id"
          item-title="reason"
          :items="cancellationReasons"
        />
        <p
          v-if="user.id === authUser.id"
          class="text-body-1"
        >
          {{ t('shift.cancellation.confirmation_message_1') }}
          <strong>{{ toLongDateWithDay(shift.date) }}
            {{ t('global.at') }} {{ shift.location?.name }}</strong>?
        </p>
        <p
          v-else
          class="text-body-1"
        >
          {{ t('shift.cancellation.confirmation_message_2') }}
          <strong>{{ user.full_name }} {{ t('global.for') }} {{ toLongDateWithDay(shift.date) }}
            {{ t('global.at') }} {{ shift.location?.name }}</strong>?
          {{ t('shift.cancellation.confirmation_message_3') }}
        </p>
        <br>
        <div v-if="user.id === authUser.id">
          <strong class="text-font">{{ t('shift.cancellation.current_info') }}</strong>
          <br>
          <ul class="list-group ml-4">
            <li class="list-group-item">
              {{ t('shift.cancellation.rate') }}: <strong>{{ cancellationRate }}%</strong>
            </li>
            <li class="list-group-item">
              {{ t('shift.cancellation.last_minute_rate') }}: <strong>{{ lastMinuteCancellationRate }}%</strong>
            </li>
          </ul>
        </div>
        <template #actions>
          <v-btn
            :text="t('shift.dont_cancel')"
            color="dark-grey"
            @click="cancellationDialog = false"
          />
          <v-btn
            v-if="shift.spouse_assigned"
            :disabled="!selectedReasonId"
            :text="t('shift.cancellation.with_spouse')"
            color="error"
            @click="performCancellation(true)"
          />
          <v-btn
            :disabled="!selectedReasonId"
            :text="t('shift.cancellation.title')"
            color="error"
            @click="performCancellation(false)"
          />
        </template>
      </global-card>
    </v-dialog>
    <v-dialog
      v-model="lastMinCancellationDialog"
      max-width="600px"
    >
      <global-card
        heading-color="error"
        :title="t('shift.cancellation.last_minute')"
      >
        <p>
          {{ t('shift.cancellation.confirmation_message_4') }} {{ scheduledFor }} ({{ toUniversalDate(shift.date) }}).
          {{ t('shift.cancellation.confirmation_message_5') }} <a href="/dayoverseers">{{ t('dayoverseer.overseer') }}</a>
          {{ t('shift.cancellation.confirmation_message_6') }}
          <strong>{{ weekdayName }}s</strong>.
        </p>
        <div>
          <strong>{{ t('shift.cancellation.current_info') }}:</strong>
          <ul class="list-group">
            <li class="list-group-item">
              {{ t('shift.cancellation.rate') }}: <strong>{{ cancellationRate }}%</strong>
            </li>
            <li class="list-group-item">
              {{ t('shift.cancellation.last_minute_rate') }}: <strong>{{ lastMinuteCancellationRate }}%</strong>
            </li>
          </ul>
        </div>
        <template #actions>
          <v-btn
            :text="t('global.ok')"
            color="primary"
            @click="lastMinCancellationDialog = false"
          />
        </template>
      </global-card>
    </v-dialog>
  </v-btn>
</template>

<script setup lang="ts">
import {
  useAuth,
  useDate,
  usePermissions,
} from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  user: App.Models.User;
  shift: App.ApiResources.PublisherWithShiftDetailsResource;
}>();

const emit = defineEmits(['shift:cancelled']);

const { t } = useI18n();

const { showSnackMessage } = useErrorStore();
const { authUser } = useAuth();
const { can } = usePermissions();
const {
  toUniversalDate,
  toLongDateWithDay,
  toLongDay,
  daysDiffFromToday,
} = useDate();

const cancellationReasons = ref<Array<App.Models.ShiftCancellationReason>>([]);
const selectedReasonId = ref<number>();
const lastMinCancellationDialog = ref(false);
const cancellationDialog = ref(false);

const canCancelLastMinute = computed<boolean>(() => can('manage-shifts'));
const isLastMinute = computed<boolean>(() => props.shift?.is_last_minute ?? false);
const cancellationRate = computed<string>(() => (Number.parseFloat(props.user.calc_cancellation_rate) * 100).toFixed(2));
const lastMinuteCancellationRate = computed<string>(() => (Number.parseFloat(props.user.calc_last_minute_cancellation_rate) * 100).toFixed(2));
const weekdayName = computed<string>(() => {
  if (props.shift.is_holiday === true) {
    return t('date.bank_holiday');
  }
  return toLongDay(props.shift.date);
});

const scheduledFor = computed(() => {
  const shiftDateDiff = daysDiffFromToday(props.shift.date);
  if (shiftDateDiff === 0) {
    return t('date.today');
  }
  if (shiftDateDiff === 1) {
    return t('date.tomorrow');
  }
  return weekdayName;
});

function show(): void {
  if (isLastMinute.value && !canCancelLastMinute.value) {
    lastMinCancellationDialog.value = true;
  }
  else {
    cancellationDialog.value = true;
  }
}

async function loadCancellationReasons(): Promise<void> {
  try {
    const response = await axios.get('/api/shiftcancellationreasons');
    cancellationReasons.value = response.data.data;
    if (cancellationReasons.value.length === 1) {
      selectedReasonId.value = cancellationReasons.value[0].id;
    }
  }
  catch (error) {
    showSnackMessage(t('cancellation_reason.cannot_load'));
  }
}

async function performCancellation(withSpouse = false): Promise<void> {
  try {
    await axios.patch(`/api/users/${props.user.id}/shifts/${props.shift.shift_id}?action=cancel`, {
      cancellation_reason_id: selectedReasonId.value,
    });
    if (withSpouse && props.user?.spouse_id) {
      await axios.patch(`/api/users/${props.user?.spouse_id}/shifts/${props.shift.shift_id}?action=cancel`, {
        cancellation_reason_id: selectedReasonId.value,
      });
    }
    emit('shift:cancelled');
    cancellationDialog.value = false;
  }
  catch (error) {
    showSnackMessage(t('shift.cancellation.error_message'));
  }
}

onMounted(() => {
  loadCancellationReasons();
});
</script>

import type { Moment } from 'moment';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useConfig } from './useConfig';
import { useSettings } from './useSettings';

const { getSetting } = useSettings();
const { getConfig } = useConfig();

export function useDate() {
  const { d } = useI18n();

  const getAsDate = (date: NullableDateOptionType = undefined): Date => moment(date).toDate();

  const startOfToday = (): Moment => moment().startOf('day');
  const endOfToday = (): Moment => moment().endOf('day');
  const startOfTomorrow = (): Moment => moment().add(1, 'day').startOf('day');
  const endOfTomorrow = (): Moment => moment().add(1, 'day').endOf('day');

  const currentUnix = (): number => moment().unix();
  const unixFilter = (timestamp: number): boolean => currentUnix() - timestamp < 1800;

  const formatDate = (date: NullableDateOptionType, format: string | undefined = 'YYYY-MM-DD'): string => moment(date).format(format);
  // sat
  const toShortDay = (date: NullableDateOptionType): string => d(getAsDate(date), 'short_day');

  // 03 Feb
  const toShortDate = (date: NullableDateOptionType): string => d(getAsDate(date), 'short');

  // Tue, 03 Feb
  const toShortDatewithDay = (date: NullableDateOptionType): string => d(getAsDate(date), 'short_with_day');

  // 2024-01-17 21:13:31
  const toDateTime = (date: NullableDateOptionType): string => moment(date).format('YYYY-MM-DD HH:mm:ss');

  // Wednesday, January 17th 2024
  function toLongDateWithDay(date: NullableDateOptionType): string {
    return d(getAsDate(date), 'long_date_with_day');
  }

  // Wed, January 17th 2024
  function toLongDateWithShortDay(date: NullableDateOptionType): string {
    return d(getAsDate(date), 'long_date_with_short_day');
  }

  // Wednesday, January 17th 2024 08:00am
  const toLongDateWithDayAndTime = (date: NullableDateOptionType): string => d(getAsDate(date), 'long_date_with_day_and_time');

  // January 17th 2024 - 08:00
  const toLongDateWithTime = (date: NullableDateOptionType): string => d(getAsDate(date), 'long_date_with_time');

  // January 17th 2024
  const toLongDate = (date: NullableDateOptionType): string => d(getAsDate(date), 'long');

  // YYYY-MM-DD
  const toUniversalDate = (date: NullableDateOptionType): string => moment(date).format('YYYY-MM-DD');

  // YYYY-MM-DD HH:mm:ss
  const parseDateAndTime = (date, time): Date => moment(
    `${date} ${time}`,
    'YYYY-MM-DD HH:mm:ss',
  ).toDate();

  // Wednesday
  const toLongDay = (date: NullableDateOptionType): string => d(getAsDate(date), 'long_day');

  // Wed Jan 17 2024 23:59:59 GMT+0000
  // Return end of today if no parameters passed.
  const endOf = (date: NullableDateOptionType, unit: moment.unitOfTime.DurationConstructor = 'day'): Moment => moment(date).endOf(unit);

  // Wed Jan 17 2024 00:00:00 GMT+0000
  // Return start of today if no parameters passed.
  const startOf = (date: NullableDateOptionType, unit: moment.unitOfTime.DurationConstructor = 'day'): Moment => moment(date).startOf(unit);

  // 1
  const daysDiffFromToday = (date: NullableDateOptionType): number => moment(date).endOf('day').diff(endOfToday(), 'days');

  // 8:00am
  const toShortTime = (date: NullableDateOptionType, inputFormat: string = 'HH:mm:ss'): string => moment(date, inputFormat).format('h:mma');

  // 2024
  const thisYear = (): number => moment().year();

  // As Date -> Max Current Needs Date
  const maxCurrentNeedsDate = (): Date => moment().add(getSetting('number_of_day_available_for_current_need_page'), 'days').startOf('day').toDate();

  const isSameOrBefore = (
    date1: DateOptionType,
    date2: NullableDateOptionType = undefined,
  ): boolean => moment(date1).isSameOrBefore(moment(date2));

  const isSameOrAfter = (
    date1: DateOptionType,
    date2: NullableDateOptionType = undefined,
  ): boolean => moment(date1).isSameOrAfter(moment(date2));

  const isAfterToday = (date: DateOptionType): boolean => moment().isBefore(date);

  const isBeforeToday = (date: DateOptionType): boolean => moment().isAfter(date);

  const getToday = (): Moment => moment();

  const getAsValidDateOrNull = (date: NullableDateOptionType): Date | null => {
    const momentDate = moment(date);
    return moment(date).isValid() ? momentDate.toDate() : null;
  };

  const addToDate = (
    date: NullableDateOptionType,
    number: number = 1,
    unit: moment.unitOfTime.DurationConstructor = 'days',
  ): Moment => moment(date).add(number, unit);

  const subtractFromDate = (
    date: DateOptionType | undefined,
    number: number = 1,
    unit: moment.unitOfTime.DurationConstructor = 'days',
  ): Moment => moment(date).subtract(number, unit);

  const isInFutureByAtLeast = (
    date: DateOptionType,
    number: number = 7,
    unit: moment.unitOfTime.DurationConstructor = 'days',
  ): boolean => moment().diff(moment(date), unit) < number;

  const fromNow = (date: DateOptionType): string => moment(date).fromNow();

  const calculateAndFormatEndTime = (time: string, duration: string): string => {
    const shiftDuration = moment.duration(duration);
    const t = moment(time, 'hh:mm:ss').add(shiftDuration);
    return toShortTime(t);
  };

  const getTimeDiff = (start: string, end: string, inputFormat: string = 'HH:mm', unit: moment.unitOfTime.DurationConstructor = 'minutes'): number => {
    const startTime = moment(start, inputFormat);
    const endTime = moment(end, inputFormat);
    const diff = endTime.diff(startTime, unit);
    return Math.abs(diff);
  };

  const timeSince = (date: DateOptionType): number => moment().diff(date, 'years');

  const isoWeekday = (date: DateOptionType): number => moment(date).isoWeekday();

  const isTodayOrTomorrow = (date: DateOptionType): boolean => moment(date).isAfter(startOfToday()) && moment(date).isBefore(endOfTomorrow());

  const isTomorrow = (date: DateOptionType): boolean => {
    const localDate = moment(date);
    return localDate.isSameOrAfter(startOfTomorrow()) && localDate.isSameOrBefore(endOfTomorrow());
  };

  const isToday = (date: NullableDateOptionType): boolean => {
    if (date === undefined) {
      return false;
    }
    const localDate = moment(date);

    return localDate.isSameOrAfter(startOfToday()) && localDate.isSameOrBefore(endOfToday());
  };

  const contactsVisibleForDays: number = Number.parseInt(getConfig('smpw.shift_contacts_visible_for_days'), 10);
  const planningWeeks: number = Number.parseInt(getConfig('smpw.planning_weeks'), 10);

  const contactsVisible = (date: string): boolean => {
    const shiftDate = getAsDate(`${date}T00:00`);
    const maxDate = getAsDate();
    maxDate.setDate(maxDate.getDate() + contactsVisibleForDays);
    return shiftDate < maxDate;
  };

  const planningDates = (): { start: Date; end: Date } => {
    const startDate = getAsDate();
    const endDate = addToDate(undefined, planningWeeks, 'weeks');
    return { start: startDate, end: endDate.toDate() };
  };

  return {
    addToDate,
    calculateAndFormatEndTime,
    contactsVisible,
    daysDiffFromToday,
    endOf,
    fromNow,
    getAsDate,
    getAsValidDateOrNull,
    getTimeDiff,
    getToday,
    isAfterToday,
    isBeforeToday,
    isoWeekday,
    isSameOrAfter,
    isSameOrBefore,
    isTodayOrTomorrow,
    isToday,
    isTomorrow,
    maxCurrentNeedsDate,
    isInFutureByAtLeast,
    planningDates,
    startOf,
    subtractFromDate,
    thisYear,
    toDateTime,
    toLongDateWithDayAndTime,
    toLongDateWithDay,
    toLongDateWithTime,
    toLongDate,
    toLongDay,
    toShortDate,
    toShortDay,
    toShortTime,
    parseDateAndTime,
    toUniversalDate,
    unixFilter,
    currentUnix,
    toLongDateWithShortDay,
    formatDate,
    contactsVisibleForDays,
    timeSince,
    toShortDatewithDay,
  };
}

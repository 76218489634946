<template>
  <v-btn
    color="primary"
    @click="dialog = true"
  >
    <v-badge
      v-if="selectedImages.length"
      floating
      color="warning"
      class="ms-1"
      :content="selectedImages.length"
    >
      <v-icon icon="fas fa-images" />
    </v-badge>
    <v-icon
      v-else
      icon="fas fa-images"
    />
    <v-dialog
      v-model="dialog"
    >
      <global-card
        :title="t('images.select')"
        closable
        @close="dialog = false"
      >
        <image-gallery
          v-model="locationImages"
          class="mt-10"
          selectable
          read-only
        />
        <template #actions>
          <v-btn
            color="dark-grey"
            :text="t('actions.close')"
            @click="close"
          />
          <v-btn
            :text="t('global.ok')"
            color="primary"
            @click="onImagesSelected"
          />
        </template>
      </global-card>
    </v-dialog>
  </v-btn>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ImageGallery from '../images/ImageGallery.vue';

const props = defineProps<{
  images?: any;
}>();

const { t } = useI18n();

const dialog = ref(false);
const locationImages = ref<Array<any>>([]);
const selectedImages = defineModel<any>();

function updateLocationImages() {
  locationImages.value = props.images?.filter((image) => !image.deleted).map((image) => {
    const imageClone = { ...image };
    imageClone.selected = !!selectedImages.value.some((selectedImage) => selectedImage.id === image.id);
    return imageClone;
  });
}
function close() {
  dialog.value = false;
}
function onImagesSelected() {
  selectedImages.value = locationImages.value.filter((image) => !image.deleted).filter((image) => image.selected);
  close();
}

updateLocationImages();
</script>

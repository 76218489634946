<template>
  <v-card v-on="cardListeners">
    <v-card-title
      class="d-flex flex-row justify-space-between"
      :class="`bg-${color}`"
    >
      <div>
        <span
          class="text-body-1 font-weight-bold text-wrap text-white"
          :class="{ 'text-white': color === 'primary', 'text-primary': color !== 'primary' }"
        >
          <b v-if="isFeatured">{{ t('experience.featured') }}:</b> {{ experience.title }}
        </span>
        <v-card-subtitle :class="{ 'text-white': color === 'primary', 'text-font': color !== 'primary' }">
          {{ toLongDateWithDay(experience.date) }}
          <span v-if="experience.location"> - {{ experience.location?.name }}</span>
        </v-card-subtitle>
      </div>
      <v-btn
        v-if="closable"
        icon="fas fa-times"
        color="white"
        variant="text"
        @click="emit('close')"
      />
    </v-card-title>
    <v-card-text
      class="light-grey mt-2"
    >
      <p
        class="tiptap content_paragraph"
        style="white-space: pre-wrap;"
        :class="{ 'multiline-ellipsis': truncate }"
        v-html="experience.experience"
      />
      <v-btn
        variant="plain"
        :text="t('global.read_more')"
        @click="() => readExperience(experience)"
      />
    </v-card-text>
    <v-card-actions
      v-if="closable"
    >
      <v-btn
        color="dark-grey"
        variant="elevated"
        :text="t('actions.close')"
        @click="emit('close')"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const props = defineProps<{
  experience: App.Models.Experience;
  truncate?: boolean;
  click?: Function;
  color?: string;
  isFeatured?: boolean;
  closable?: boolean;
}>();

const emit = defineEmits(['close']);

const { t } = useI18n();
const router = useRouter();
const { toLongDateWithDay } = useDate();
const cardListeners: { click?: Function } = {};
if (props.click) {
  cardListeners.click = () => {
    if (props.click) { // needed to satisfy Typescript
      props.click(props.experience);
    }
  };
}
function readExperience(experienceObj) {
  router.push({ path: `/experiences/${experienceObj.id}` });
}
</script>

import type { Component } from 'vue';
import { defineAsyncComponent } from 'vue';

const CookiePolicy = defineAsyncComponent<Component>(async () => import('@/components/CookiePolicy.vue'));
const UsernameRecovery = defineAsyncComponent<Component>(async () => import('@/components/UsernameRecovery.vue'));
const ForgottenPassword = defineAsyncComponent<Component>(async () => import('@/components/ForgottenPassword.vue'));
const LoginPage = defineAsyncComponent<Component>(async () => import('@/components/LoginPage.vue'));
const ActivateUser = defineAsyncComponent<Component>(async () => import('@/components/ActivateUser.vue'));
const ResetPassword = defineAsyncComponent<Component>(async () => import('@/components/ResetPassword.vue'));

const unauthenticatedRoutes = [
  {
    path: '/login',
    component: LoginPage,
    name: 'Login',
    meta: { requiresAuth: false },
  },
  {
    path: '/policies/cookies',
    component: CookiePolicy,
    name: 'Cookie Policy',
    meta: { requiresAuth: false },
  },
  {
    path: '/username/recovery',
    component: UsernameRecovery,
    name: 'Recover Username',
    meta: { requiresAuth: false },
  },
  {
    path: '/password/request',
    component: ForgottenPassword,
    name: 'Forgotten Password',
    meta: { requiresAuth: false },
  },
  {
    path: '/activateuser/:id',
    component: ActivateUser,
    name: 'Activate User',
    meta: { requiresAuth: false },
  },
  {
    path: '/password/reset/:token',
    component: ResetPassword,
    name: 'Reset Password',
    meta: { requiresAuth: false },
  },

];

export default unauthenticatedRoutes;

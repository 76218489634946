<template>
  <div class="mb-1">
    <v-row class="mx-1">
      <image-slideshow
        v-if="readOnly"
        :id="`${name}-slide-show`"
        :images="slideShowImages"
        :index="slideShowIndex"
        @close="slideShowIndex = null"
      />
      <v-table
        v-for="(image, imageIndex) in images.filter((image) => !image.deleted)"
        :key="imageIndex"
      >
        <v-row>
          <v-col>
            <div
              class="image"
              :style="{ border: getBorder(image), backgroundImage: `url(${getHref(image)})`, width: thumbnailWidth, height: thumbnailHeight }"
              @click.self="onImageClick(imageIndex, image)"
            >
              <v-checkbox
                v-if="selectable"
                v-model="image.selected"
                class="ms-1"
              />
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="!smallThumbnails"
          style="margin-left: 0px; margin-bottom: 15px;"
        >
          <v-col :style="`width: ${thumbnailWidth}`">
            <v-text-field
              v-if="!readOnly"
              v-model="image.title"
              :placeholder="t('global.title')"
            />
            <span
              v-if="readOnly"
            >{{ image.title }}</span>
          </v-col>
        </v-row>
      </v-table>
    </v-row>
    <v-row
      v-if="!readOnly"
      class="ms-1 me-1"
    >
      <v-col>
        <v-btn
          v-if="(selectedImageCount > 0)"
          color="error"
          class="mt-2 me-1"
          :text="t('images.delete_selected_images', selectedImageCount)"
          @click.prevent="onDeleteClick"
        />
        <image-upload-button
          class="mt-2"
          :caption="t('images.upload')"
          :on-upload-image="onUploadImage"
          :on-upload-image-guard="onUploadImageGuard"
          :multiple-images="!isSingleImage"
          :error="error"
          :error-messages="errorMessages"
        />
      </v-col>
    </v-row>
    <v-alert
      v-if="(images.length === 0) && readOnly"
      class="text-font"
      :text="t('images.none_available')"
    />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import ImageSlideshow from './ImageSlideshow.vue';
import ImageUploadButton from './ImageUploadButton.vue';
import { useImageValidation } from './useImageValidation';

const props = withDefaults(defineProps<{
  name?: string;
  readOnly?: boolean;
  enableSlideShow?: boolean;
  smallThumbnails?: boolean;
  selectable?: boolean;
  maxImages?: number;
  squareImage?: boolean;
  maxImageKilobyteSize?: number;
  minImageDimSize?: number;
  minWidthImageDimSize?: number;
  minHeightImageDimSize?: number;
  errorMessages?: any;
  error?: boolean;
}>(), {
  maxImages: () => 0,
  squareImage: () => false,
  maxImageKilobyteSize: () => 10240,
});
const { t } = useI18n();
const { validateImage } = useImageValidation();

const images = defineModel<any>();
const slideShowIndex = ref<number | null>(null);

const thumbnailWidth = computed(() => {
  if (props.smallThumbnails) {
    return '90px';
  }
  return '300px';
});

const thumbnailHeight = computed(() => {
  if (props.smallThumbnails && props.squareImage) {
    return '90px';
  }

  if (props.smallThumbnails) {
    return '60px';
  }

  if (props.squareImage) {
    return '300px';
  }
  return '200px';
});

const selectedImageCount = computed(() => images.value.filter((image) => !image.deleted && image.selected).length);

function getHref(image) {
  return image.base_64_image ? image.base_64_image : image.signed_file_url;
}

const slideShowImages = computed(() => images.value.filter((image) => !image.deleted).map((image) => ({
  title: image.title,
  href: getHref(image),
})));

const isSingleImage = computed(() => props.maxImages === 1);

function onImageClick(imageIndex, image) {
  if (props.enableSlideShow) {
    slideShowIndex.value = imageIndex;
  }
  else if (props.selectable) {
    image.selected = !image.selected;
  }
}

function getBorder(image) {
  if (props.selectable && image.selected) {
    return '2px solid #02979d';
  }
  return '';
}

function onUploadImage(uploadedImages) {
  let lowestId = -1;
  if (images.value.length > 0) {
    lowestId = Math.min.apply(null, images.value.map((image) => image.id));
    lowestId = lowestId > -1 ? -1 : lowestId - 1;
  }
  uploadedImages.forEach((newImage) => {
    images.value.push({
      id: lowestId,
      title: newImage.title,
      base_64_image: newImage.data,
      selected: false,
      deleted: false,
    });
    lowestId -= 1;
  });
  if (props.maxImages > 0) {
    while (images.value.filter((image) => !image.deleted).length > props.maxImages) {
      images.value.filter((image) => !image.deleted).find(() => true).deleted = true;
    }
  }
}
function onUploadImageGuard(image) {
  return validateImage(
    image,
    props.squareImage,
    props.maxImageKilobyteSize,
    props.minImageDimSize,
    props.minWidthImageDimSize,
    props.minHeightImageDimSize,
  );
}

function onDeleteClick() {
  images.value = images.value.map((image) => {
    if (image.selected) {
      image.deleted = true;
    }
    return image;
  });
}
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>

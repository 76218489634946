<template>
  <v-table :mobile="xs">
    <thead>
      <tr>
        <th style="width: 260px">
          {{ t('global.name') }}
        </th>
        <th style="width: 180px">
          {{ t('role.title') }}
        </th>
        <th>{{ t('global.description') }}</th>
        <th>
          {{ t('actions.delete') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(member, index) in members"
        :key="index"
      >
        <td>
          <v-autocomplete
            v-model="/** @ts-ignore */member.id"
            :label="t('member.title')"
            :items="users"
            item-title="name"
            item-value="id"
            hide-details
            :return-object="false"
            class="mb-margin"
          />
        </td>
        <td>
          <v-select
            v-model="member.pivot.role"
            :label="t('role.title', 2)"
            :items="roles"
            hide-details
            class="mb-margin"
          />
        </td>
        <td>
          <v-textarea
            v-model="member.pivot.role_description"
            hide-details
            auto-grow
            rows="1"
            class="mb-margin"
          />
        </td>
        <td>
          <v-btn
            color="error"
            class="mb-margin"
            @click="deleteItem(index)"
          >
            <v-icon icon="fas fa-trash" />
          </v-btn>
        </td>
      </tr>
      <tr>
        <td>
          <v-autocomplete
            v-model="/** @ts-ignore */newUser"
            :label="t('member.title')"
            :items="users"
            item-title="name"
            item-value="id"
            hide-details
            :return-object="true"
            clear-on-select
            class="mb-margin"
          />
        </td>
        <td />
        <td />
      </tr>
    </tbody>
  </v-table>
</template>

<script setup lang="ts">
import { useAdminStore } from '@/stores/admin';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

const { xs } = useDisplay();
const { t } = useI18n();
const members = defineModel<Array<App.DataTables.UserWithPivot>>({ default: [] });

const newUser = ref<App.DataTables.UserWithPivot>();

const roles = ref([
  { value: 'member', title: t('member.title') },
  { value: 'assistant', title: t('global.assistant') },
]);

function newMemberSelected() {
  if (newUser.value) {
    newUser.value.pivot = { role: 'member', role_description: '' };
    members.value.push(newUser.value);
    newUser.value = undefined;
  }
}

function deleteItem(index) {
  members.value.splice(index, 1);
}

const adminStore = useAdminStore();
const { users } = storeToRefs(adminStore);
watch(newUser, () => {
  newMemberSelected();
});
</script>

<style scoped>
@media (max-width: 767px){
  table,tr,td{
    display: block;
  }
  .mb-margin {
    margin-top: 8px !important;
  }
}
</style>

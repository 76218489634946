<template>
  <v-btn
    size="x-small"
    class="border-right pub_card_action"
    :prepend-icon="icon"
    :text="caption"
    stacked
    variant="plain"
    @click="emit('click')"
  />
</template>

<script setup lang="ts">
defineProps<{
  icon: string;
  caption: string;
}>();

const emit = defineEmits(['click']);
</script>

<style scoped>
.pub_card_action:hover {
  filter: brightness(120%)
}
</style>

<template>
  <div>
    <v-alert
      v-if="size === 'large'"
      :color="shiftTag.alert_type"
      class="me-1"
      style="white-space: pre-wrap;"
      :icon="`fas fa-${shiftTag.icon}`"
    >
      <span v-html="buttonText" />
    </v-alert>
    <v-btn
      v-if="size === 'medium'"
      v-tooltip="shiftTag.description"
      :color="shiftTag.alert_type"
      variant="flat"
      :ripple="false"
      class="me-1"
      :prepend-icon="`fas fa-${shiftTag.icon}`"
      :text="buttonText"
    />
    <v-btn
      v-if="size === 'small' || size === 'x-small'"
      v-tooltip="shiftTag.name.en ?? shiftTag.name"
      :color="shiftTag.alert_type"
      :ripple="false"
      variant="flat"
      :block="block"
      :size="size"
    >
      <v-icon
        :icon="`fas fa-${shiftTag.icon}`"
      />
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  shiftTag: App.ApiResources.BasicShiftTagResource | App.Models.ShiftTag;
  size?: 'x-small' | 'small' | 'medium' | 'large';
  block?: boolean;
}>(), {
  size: () => 'small',
  block: () => false,
});

const buttonText = computed(() => {
  if (props.size === 'medium') {
    return props.shiftTag.name.en ?? props.shiftTag.name;
  }
  if (props.size === 'large') {
    return props.shiftTag.description;
  }

  return '';
});
</script>
